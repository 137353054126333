import React from 'react';
var $ = jQuery
const algoliasearch = require("algoliasearch");
const client = algoliasearch("E0210K79FY", "e72e7ddf6aee272cbf0937c26a7b93be");
const index = client.initIndex("gildi_prod");
const pageTypes = {
    "gildi_cms.NewsPage": "Fréttir og tilkynningar",
    "gildi_cms.StandardPage": "Síða",
    // "gildi_cms.QuestionAndAnswer": "Algengar spurningar"
}
class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            all: []
        }

        this.searchInput = React.createRef();
    }

    onSearch(e) {
        e.preventDefault()
        let query = this.searchInput.current.value;
        if(query.length > 2){
            index.search(query, {'attributesToRetrieve': ['wagtail_obj_id', 'formatted_search'], 'filters':'locale:'+this.props.language, 'distinct': 1})
            .then(({ hits }) => {
                const all = hits.filter(function(d){ return Object.keys(pageTypes).some(element => {
                    if(d.objectID.includes(element)) {
                        return true;
                    }
                    return false;
                }) });

                this.setState({
                    all: all,
                })
            })
            .catch(err => {
                console.log(err);
            });
        } else { 
            // Clear search
            this.setState({
                all: []
            })
        }
    }

    clearResults() {
        this.setState({
            all: []
        })

        this.searchInput.current.value = '';
    }

    render_results(results) {
        if(results){
            return results.map((item, i) => {
                return (<div className="search-result">
                    <div className="result-type">{pageTypes[item.objectID.split(":")[0]]}</div>
                    <div className="w-100 d-flex justify-content-between row" key={i} dangerouslySetInnerHTML={{__html: item.formatted_search}}></div>
                </div>);
            })
        }
        return []
    }

    render() {

        return (
            <>
            <div className="position-relative search-container inner">
                <label className="bold visually-hidden" htmlFor="searchInput">Hverju ertu að leita að?</label>
                <input id="search" className="w-100 search mb-0 mb-lg-5" placeholder="Hverju ertu að leita að?" onChange={this.onSearch.bind(this)} ref={this.searchInput} type="text"/>
                <div className="close-icon" onClick={this.clearResults.bind(this)}>
                    <svg width="17px" height="18px" viewBox="0 0 17 18" version="1.1">
                        <g id="HD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Gildi-forsida-1920-leit-B" transform="translate(-1816.000000, -48.000000)" fill="#3B9E93">
                                <path d="M1830.92373,48.824486 L1832.6915,50.5922529 L1826.15049,57.132486 L1832.6915,63.6737284 L1830.92373,65.4414953 L1824.38249,58.900486 L1817.84225,65.4414953 L1816.07449,63.6737284 L1822.61549,57.132486 L1816.07449,50.5922529 L1817.84225,48.824486 L1824.38249,55.365486 L1830.92373,48.824486 Z" id="Combined-Shape-Copy-3"></path>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <div className="search-results-container">{this.render_results(this.state.all)}</div>
            </>
        )
    }
}

export default (Search);
