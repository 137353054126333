import React from 'react'
import {createRoot} from 'react-dom/client'
import Wrapper from './wrapper'

function searchContainer(element) {
  var options = element.dataset;
  var root = createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-search]');
  elements.forEach(searchContainer);
}); 